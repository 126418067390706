import { gql, useMutation } from '@apollo/client'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'
import { CartContext } from '../providers/CartContext'

const QueryAddToCart = () => {
  const router = useRouter()
  const { cart } = useContext(CartContext)

  const [updateCart] = useMutation(
    gql`
      mutation cartLinesAdd($lines: [CartLineInput!]!, $cartId: ID!) {
        cartLinesAdd(lines: $lines, cartId: $cartId) {
          cart {
            id
          }
        }
      }
    `
  )

  useEffect(() => {
    if (!router.query?.addToCart) return
    if (typeof router.query.addToCart !== 'string') return
    if (!cart.id) return
    if (cart.lastUpdate !== 0) return

    updateCart({
      variables: {
        cartId: cart.id,
        lines: [
          {
            merchandiseId: window.atob(router.query.addToCart),
            quantity: 1,
          },
        ],
      },
    })
  }, [cart.id, cart.lastUpdate, router.query.addToCart, updateCart])

  return null
}

export default QueryAddToCart
